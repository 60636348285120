import GPUBox from './GPUBox';
import useWebSocket from 'react-use-websocket';
import { useState, useLayoutEffect } from 'react';

const {REACT_APP_WS_URL} = process.env;

const GPUBoxWS = ({server_id}) => {
    const [data, setData] = useState(null);

    const {lastJsonMessage } = useWebSocket(`${REACT_APP_WS_URL}/getGPULoading/${server_id}`,{
        share: false,
        shouldReconnect: () => true,
    });

    useLayoutEffect(() => {
        if (lastJsonMessage !== null) {
            const obj = lastJsonMessage;
            setData(obj);
        }
    }, [lastJsonMessage]);

    return (
        <GPUBox 
        key={server_id}
        data={data} />
    )
}

export default GPUBoxWS;