import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import { useState, useEffect } from "react";

import GPUBoxWS from "./GPUBoxWS";

const {REACT_APP_HTTP_URL} = process.env;

const GPUStatus = ({
    gridColumn = "span 5",
    gridRow = "span 10"
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [serverIDList, setServerIDList] = useState([]);

    useEffect(() => {
        const fetchServerIDList = async () => {
            const response = await fetch(`${REACT_APP_HTTP_URL}/serverIDList`);
            const data = await response.json();
            setServerIDList(data);
        };
        fetchServerIDList();
    }, []);

    return (
        <Box
            gridColumn={gridColumn}
            gridRow={gridRow}
            backgroundColor={colors.primary[400]}
        >
            {serverIDList.map((server_id) => (
                <GPUBoxWS 
                    key = {server_id}
                    server_id={server_id} 
                />
            ))}
        </Box>
    );
};

export default GPUStatus;