import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useWebSocket from "react-use-websocket";
import { useState, useEffect } from "react";

const {REACT_APP_WS_URL} = process.env;

const GbpsRankTable = ({gridColumn="span 6", gridRow="span 4"}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const GbpsRankColumns = [
        // {   field: 'timestamp', 
        //     headerName: 'Time', 
        //     flex: 0.5
        // },
        {   field: 'id',
            headerName: 'Link',
            flex: 1.3,
        },
        {
            field: 'current_gbps',
            headerName: 'Current gbps',
            type: 'number',
            flex: 1.0,
        },
        {
            field: 'byte_count',
            headerName: 'Byte Count',
            type: 'number',
            flex: 1,
        },
        {
            field: 'pkt_count',
            headerName: 'Pkt Count',
            type: 'number',
            flex: 1,
        },
    ];

    const [GbpsRankRows, setGbpsRankRows] = useState([]);

    const {lastJsonMessage} = useWebSocket(`${REACT_APP_WS_URL}/getGbpsRank`, {
        share: false,
        shouldReconnect: () => true,
    }
    );
    useEffect(() => {
        if (lastJsonMessage !== null) {
            const data = lastJsonMessage;
            setGbpsRankRows(data);
        }
    }, [lastJsonMessage]);

    return (
        <Box
            gridColumn={gridColumn}
            gridRow={gridRow}
            backgroundColor={colors.primary[400]}
        >
            <Box 
                height="100%" 
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        border: "none",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none"
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400]
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundcolor: colors.blueAccent[700]
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            > 
                <DataGrid rows={GbpsRankRows} columns={GbpsRankColumns} rowHeight={25} disableRowSelectionOnClick hideFooter/>
            </Box>
        </Box>
    );
};

export default GbpsRankTable;