import { tokens } from "../theme";
import { Box, Typography, useTheme } from "@mui/material";
import GPULoading from "./GPULoading";

const GPUBox = ({data}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return(
        <Box width="100%" height="25%" bgcolor={colors.primary[800]}>
            {/* Title */}
            <Typography
                marginLeft="1%"
                width="100%"
                height="10%"
                variant="h5"
                fontWeight="bold"
                sx={{ colors: colors.grey[100] }}
            >
                {(data ? "Server: " + data.server_id : "Loading...")}
            </Typography> 
            
            {/* Gauge (First Row) */}
            <Box width="100%" height="40%" display="flex" m="1%">
                <Box width="24%" height="auto">
                    <GPULoading gpu_id={1} gpu_loading={data ? data.gpu_loadings[0] : 0}/>
                </Box>
                <Box width="24%" height="auto">
                    <GPULoading gpu_id={2} gpu_loading={data ? data.gpu_loadings[1] : 0}/>
                </Box>
                <Box width="24%" height="auto">
                    <GPULoading gpu_id={3} gpu_loading={data ? data.gpu_loadings[2] : 0}/>
                </Box>
                <Box width="24%" height="auto">
                    <GPULoading gpu_id={4} gpu_loading={data ? data.gpu_loadings[3] : 0}/>
                </Box>
            </Box>

            {/* Gauge (Second Row) */}
            <Box width="100%" height="40%" display="flex" m="1%">
                <Box width="24%" height="auto">
                    <GPULoading gpu_id={5} gpu_loading={data ? data.gpu_loadings[4] : 0}/>
                </Box>
                <Box width="24%" height="auto">
                    <GPULoading gpu_id={6} gpu_loading={data ? data.gpu_loadings[5] : 0}/>
                </Box>
                <Box width="24%" height="auto">
                    <GPULoading gpu_id={7} gpu_loading={data ? data.gpu_loadings[6] : 0}/>
                </Box>
                <Box width="24%" height="auto">
                    <GPULoading gpu_id={8} gpu_loading={data ? data.gpu_loadings[7] : 0}/>
                </Box>

            </Box>
        </Box>
    );
    
};

export default GPUBox;