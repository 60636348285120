import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useWebSocket from "react-use-websocket";
import { useState, useEffect } from "react";

const {REACT_APP_WS_URL} = process.env;

const PfcCountRankTable = ({gridColumn="span 5", gridRow="span 4"}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const pfcCountRankColumns = [
        // {   field: 'timestamp', 
        //     headerName: 'Time', 
        //     flex: 0.5
        // },
        {   field: 'id',
            headerName: 'Link',
            flex: 1.3,
        },
        {
            field: 'pfc_count_change',
            headerName: 'Pfc Count Change',
            type: 'number',
            flex: 1.0,
        },
        {
            field: 'cumulative_pfc_count_change',
            headerName: 'Cumulative Pfc count change',
            type: 'number',
            flex: 1.2,
        },
    ];

    const [pfcCountRankRows, setPfcCountRankRows] = useState([]);

    const {lastJsonMessage} = useWebSocket(`${REACT_APP_WS_URL}/getPfcCountRank`, {
        share: false,
        shouldReconnect: () => true,
    });
    useEffect(() => {
        if (lastJsonMessage !== null) {
            const data = lastJsonMessage;
            setPfcCountRankRows(data);
        }
    }, [lastJsonMessage]);

    return (
        <Box
            gridColumn={gridColumn}
            gridRow={gridRow}
            backgroundColor={colors.primary[400]}
        >
            <Box 
                height="100%" 
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        border: "none",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none"
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400]
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundcolor: colors.blueAccent[700]
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >    
                <DataGrid rows={pfcCountRankRows} columns={pfcCountRankColumns} rowHeight={25} disableRowSelectionOnClick hideFooter/>
            </Box>
        </Box>
    );
};

export default PfcCountRankTable;