import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import ProgressCircle from  "./ProgressCircle";

const StatBox = ({ title, subtitle, icon, progress }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box width="100%" height="100%" display="flex" justifyContent="space-between">
            <Box width="50%" m="auto">
                <Typography
                    variant="h4"
                    align='center'
                    fontWeight="bold"
                    sx={{ colors: colors.grey[100] }}
                >
                    {icon}
                </Typography> 
                <Typography
                    variant="h4"
                    align='center'
                    fontWeight="bold"
                    sx={{ colors: colors.grey[100] }}
                >
                    {title}
                </Typography> 
                <Typography 
                    variant="h5"
                    align='center'
                    sx={{ color: colors.greenAccent[500]}}
                >
                    {subtitle}
                </Typography>
            </Box>
            <Box width="50%" m="auto">
                <ProgressCircle progress={progress} />
            </Box>
        </Box>
          
    );
};

export default StatBox;