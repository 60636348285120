import { Box } from "@mui/material";
import GaugeChart from 'react-gauge-chart';

const GPULoading = ({gpu_id, gpu_loading}) => {

    return(
        <Box width="100%" height="100%">
            gpu-{gpu_id}
            <GaugeChart 
                key={"gpu"+gpu_id}
                id="gauge-chart2"
                nrOfLevels={20} 
                percent={gpu_loading/100}
                formatTextValue={(gpu_loading) => (gpu_loading).toString() } 
                // animate={false}
                animateDuration={2000}
            />
        </Box>
    );
};

export default GPULoading;