import { createContext, useState } from 'react';
import { ReactFlow, Background } from '@xyflow/react';
import SwitchNode from './SwitchNode.jsx';
import ServerNode from './ServerNode.jsx';
import FloatingEdge from './FloatingEdge.jsx';
import '@xyflow/react/dist/style.css';

import { useTheme } from '@mui/material';
import { tokens } from '../theme.js';

export const FocusEdgeElementsContext = createContext();

// Set object to call customized edge
const edgeTypes = {
    floating: FloatingEdge,
};

// Set object to call customized node
const nodeTypes = {
    switch: SwitchNode,
    server: ServerNode,
}

// Basic react flow setting
const snapGrid = [20, 20]; 
const defaultViewport = { x: 0, y: 0, zoom: 1.5 };

const FlowMain = (props) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode)

    const  [FocusEdgeElements, SetFocusEdgeElements] = useState([]);

    return(
        <FocusEdgeElementsContext.Provider value={[FocusEdgeElements, SetFocusEdgeElements]}>
        <ReactFlow
            nodes={props.nodes}
            edges={props.edges}
            onNodesChange={props.onNodesChange}
            onEdgesChange={props.onEdgesChange}
            snapToGrid={true}
            snapGrid={snapGrid}
            defaultViewport={defaultViewport}
            fitView
            attributionPosition="bottom-left"
            edgeTypes={edgeTypes}
            nodeTypes={nodeTypes}
        >
            <Background gap={15} color={colors.grey[100]} variant="dots" />
        </ReactFlow>
        </FocusEdgeElementsContext.Provider>
    )
}

export default FlowMain;
