import { Box } from "@mui/material";

import GPUStatus from "../components/GPUStatus";
import Topology from "../components/Topology";
import OnlineDevice from "../components/OnlineDevice";
import TrainingStatus from "../components/TrainingStatus";
import OfflineDevice from "../components/OfflineDevice";
import DeviceCount from "../components/DeviceCount";
import ConnectionFlowChart from "../components/ConnectionFlowChart";
import GbpsRankTable from "../components/GbpsRankTable";
import PfcCountRankTable from "../components/PfcCountRankTable";
import ErrorRateChart from "../components/ErrorRateChart";


const Main = () => {
    return(
        <Box 
            height="90%"
            width="100%"
        >
            <Box
                display="grid"
                height="100%"
                gridTemplateColumns="repeat(22, 1fr)"
                gridAutoRows="8.5%"
                gap="10px"
            >
                <GPUStatus 
                    gridColumn="span 5" 
                    gridRow="span 10"
                />

                <Topology 
                    gridColumn="span 9" 
                    gridRow="span 6"
                />

                <OnlineDevice 
                    gridColumn="span 2" 
                    gridRow="span 2"
                />

                <TrainingStatus 
                    gridColumn="span 2" 
                    gridRow="span 2"
                />

                <ConnectionFlowChart 
                    gridColumn="span 6" 
                    gridRow="span 5"
                />

                <OfflineDevice 
                    gridColumn="span 2" 
                    gridRow="span 2"
                />

                <DeviceCount 
                    gridColumn="span 2" 
                    gridRow="span 2"
                />

                <GbpsRankTable 
                    gridColumn="span 6" 
                    gridRow="span 4"
                />

                <PfcCountRankTable 
                    gridColumn="span 5" 
                    gridRow="span 4"
                />

                <ErrorRateChart 
                    gridColumn="span 6" 
                    gridRow="span 4"
                />

            </Box>
        </Box>
    )
}

export default Main;