import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import { useLayoutEffect, useState } from "react";
import useWebSocket from "react-use-websocket";

const {REACT_APP_WS_URL} = process.env;

const TrainingStep = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState(null);
    const {lastJsonMessage} = useWebSocket(`${REACT_APP_WS_URL}/getTrainingStatus`,{
        share: false,
        shouldReconnect: () => true,
    });

    useLayoutEffect(() => {
        if (lastJsonMessage !== null) {
            setData(lastJsonMessage);
        }
    }, [lastJsonMessage]);

    return (
        <Box
            gridColumn="span 6"
            gridRow="span 1"
            backgroundColor={colors.primary[400]}
            display="flex"
        > 
            <table style={{fontSize:'10px', textAlign:"center"}} width="100%" border="1px solid black">
                <tbody>
                    <tr>
                        <td colspan="2" style={{backgroundColor:colors.blueAccent[700]}}>Training Name</td>
                        <td colspan="4">{data ? data.training_name : "Loading..."}</td>
                    </tr>
                    <tr>
                        <td width="16%" style={{backgroundColor:colors.blueAccent[700]}}>Elapsed Time</td>
                        <td width="16%">{data ? data.elapsed_time : "Loading..."}</td>
                        <td width="20%" style={{backgroundColor:colors.blueAccent[700]}}>Total Epoch</td>
                        <td>{data ? data.total_epoch : "Loading..."}</td>
                        <td width="20%" style={{backgroundColor:colors.blueAccent[700]}}>Current Epoch</td>
                        <td>{data ? data.current_epoch : "Loading..."}</td>
                    </tr>
                </tbody>
            </table>
        </Box>
    )
}

export default TrainingStep;