import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import { Box } from "@mui/material";
import CanvasJSReact from '@canvasjs/react-charts';
import useWebSocket from "react-use-websocket";
import { useState, useEffect } from "react";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const {REACT_APP_WS_URL} = process.env;

const ConnectionFlowChart = ({gridColumn="span 6", gridRow="span 5"}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {lastJsonMessage } = useWebSocket(`${REACT_APP_WS_URL}/getConnectionFlow`,{
        share: false,
        shouldReconnect: () => true,
    });

    const [dataPoints, setDataPoints] = useState([]);
    const [minY, setMinY] = useState(0);
    const [maxY, setMaxY] = useState(0);
    useEffect(() => {
        if (lastJsonMessage !== null) {
            const data = lastJsonMessage.map((item) => {
                return {
                    label: item.connection_id,
                    y: item.count
                }
            });
            setDataPoints(data);
            const currentMinY = Math.min(...data.map((item) => item.y));
            setMinY(minY === 0 ? currentMinY : Math.min(minY, currentMinY));
            const currentMaxY = Math.max(...data.map((item) => item.y));
            setMaxY(maxY === 0 ? currentMaxY : Math.max(maxY, currentMaxY));
        }
    }, [lastJsonMessage]);

    const connectionFlowChartOption = {
        backgroundColor: colors.primary[400],
        title: {
            text: "Connections Flow Chart",
            fontColor: colors.grey[100],
            fontSize: 18
        },
        axisX : {
            labelFontColor: colors.grey[100],
            fontSize: 6,
        },
        axisY : {
            valueFormatString : "0.0##e+00",
            labelFontColor: colors.grey[100],
            fontSize: 6,
            minimum: minY*0.5, 
            maximum: maxY*1.1,
        },
        data: [
            {
                type: "bar",
                dataPoints: dataPoints
            }
        ]
    }

    return (
        <Box
            overflow="auto"
            gridColumn={gridColumn}
            gridRow={gridRow}
            backgroundColor={colors.primary[400]}
        >
            <CanvasJSChart containerProps={{height:"95%", width:"95%"}} options={connectionFlowChartOption} /> 
        </Box>
    )
}

export default ConnectionFlowChart;