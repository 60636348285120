import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import { useNodesState, useEdgesState } from "@xyflow/react";
import { useEffect } from "react";
import useWebSocket from "react-use-websocket";

import FlowMain from "./FlowMain";

import TopologyData from "../data/topology"

const {REACT_APP_WS_URL, REACT_APP_HTTP_URL} = process.env;

const Topology = ({gridColumn="span 9", gridRow="span 6"}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const completeOutlineNodes = (outlineNodes) => {
        return outlineNodes.map((node) => {
            return {
                ...node,
                id: node.id,
                type: node.node_type,
                data: {
                    width: 300,
                    type: node.detail.device_type,
                    model: node.detail.model,
                    ip: node.detail.ip,
                },
            };
        });
    };
    const completeOutlineEdges = (outlineEdges) => {
        return outlineEdges.map((edge) => {
            return {
                id: edge.id,
                source: edge.source,
                target: edge.target,
                data:{
                    outLabel: "out",
                    inLabel: "in",
                },
                style: {
                    strokeWidth: edge.traffic,
                },
                type: "floating",
                animated: true
            };
        });
    };

    const [outlineNodes, setOutlineNodes, onOutlineNodesChange] = useNodesState(completeOutlineNodes(TopologyData.nodes));
    const [outlineEdges, setOutlineEdges, onOutlineEdgesChange] = useEdgesState(completeOutlineEdges(TopologyData.edges));
    
    const {lastJsonMessage} = useWebSocket(`${REACT_APP_WS_URL}/getEdgeStatus`,{
        share: false,
        shouldReconnect: () => true,
    });

    useEffect(() => {
        if (lastJsonMessage !== null) {
            const data = lastJsonMessage;
            const edges = completeOutlineEdges(data);
            console.log(edges)
            setOutlineEdges(edges);
        }
    }, [lastJsonMessage]);
    

    return (
        <Box
            gridColumn={gridColumn}
            gridRow={gridRow}
            overflow="auto"
            display="flex"
            backgroundColor={colors.primary[400]}
        >
            <FlowMain 
                nodes={outlineNodes} 
                edges={outlineEdges} 
                onNodesChange={onOutlineNodesChange} 
                onEdgesChange={onOutlineEdgesChange} 
            />
        </Box>
    );
};

export default Topology;