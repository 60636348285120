const TopologyData = {
    nodes:[
        {
            id: "1_1",
            node_type: "switch",
            position:{
                x: 0,
                y: 0
            },
            detail:{
                width:300,
                device_type: "TBD",
                ip: "172.20.0.151",
                model:"Accton-AS9736-64D"
            }
        },
        {
            id: "1_2",
            node_type: "switch",
            position:{
                x: 350,
                y: 0
            },
            detail:{
                width:300,
                device_type: "TBD",
                ip: "172.20.0.152",
                model:"Accton-AS9736-64D"
            }
        },
        {
            id: "2_1",
            node_type: "server",
            position:{
                x: 0,
                y: 200
            },
            detail:{
                width:300,
                device_type: "TBD",
                ip: "172.20.0.162",
                model:"cict-gd2-2"
            }
        },
        {
            id: "2_2",
            node_type: "server",
            position:{
                x: 350,
                y: 200
            },
            detail:{
                width:300,
                device_type: "TBD",
                ip: "172.20.0.164",
                model:"cict-gd2-4"
            }
        }
    ],
    edges:[
        {
            id: "e1_2-1_1",
            source: "1_2",
            target: "1_1",
            traffic: 1
        },
        {
            id: "e1_1-2_1",
            source: "1_1",
            target: "2_1",
            traffic: 1
        },
        {
            id: "e1_2-2_2",
            source: "1_2",
            target: "2_2",
            traffic: 1
        }
    ]
}

export default TopologyData