import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import { Box } from "@mui/material";
import { useState, useEffect } from 'react';

const {REACT_APP_HTTP_URL} = process.env;

const DeviceCount = ({gridColumn="span 2", gridRow="span 2"}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [deviceCount, setDeviceCount] = useState({
        machineCount: 0,
        switchCount: 0,
        serverCount: 0
    });

    useEffect(() => {
        const fetchDeviceCount = async () => {
            const response = await fetch(`${REACT_APP_HTTP_URL}/deviceCount`);
            const data = await response.json();
            setDeviceCount(data);
        };
        fetchDeviceCount();
    }, []);


    return (
        <Box
            gridColumn={gridColumn}
            gridRow={gridRow}
            backgroundColor={colors.primary[400]}
            display="flex"
        >
            <table style={{fontSize:'10px', textAlign:"center"}} width="100%" border="1px solid black">
                <tbody>
                    <tr>
                        <td width="60%" style={{backgroundColor:colors.blueAccent[700]}}>Machine Count</td>
                        <td width="40%">{deviceCount.machineCount}</td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor:colors.blueAccent[700]}}>Switch Count</td>
                        <td>{deviceCount.switchCount}</td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor:colors.blueAccent[700]}}>Server Count</td>
                        <td >{deviceCount.serverCount}</td>
                    </tr>
                </tbody>
            </table>
        </Box>
    );
};

export default DeviceCount;