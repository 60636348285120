import { Handle, Position } from '@xyflow/react';
import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import { tokens } from '../theme.js' 

function ServerNode({ data, isConnectable }) {
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode) 

  return (

    <Box
     sx={{
        "& .server-node": {
          fontSize: 11,
          color: colors.grey[100],
          backgroundColor: colors.greenAccent[700],
          border: "1px solid black",
          borderRadius: 1,
          textAlign: 'center',
          width: 200,
        }

      }} 
    >
      <div className="server-node">
        {/* Node content */}
        <div><strong>{data.type}</strong></div>
        <div><strong>{data.ip}</strong></div>
        <div><strong>{data.model}</strong></div>

        {/* Create source handle */}
        <Handle
          type="source"
          position={Position.Bottom}
          onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={isConnectable}
        />

        {/* Create target handle */}
        <Handle
          type="target"
          position={Position.Top}
          onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={isConnectable}
        />
      </div>
    </Box>

  );

}

export default ServerNode;
