import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import { Box } from "@mui/material";
import StatBox from "./StatBox";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import useWebSocket from 'react-use-websocket';
import { useState, useEffect } from 'react';

const {REACT_APP_WS_URL} = process.env;

const OnlineDevice = ({gridColumn="span 2", gridRow="span 2"}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [onlineCount, setOnlineCount] = useState(0);
    const [offlineCount, setOfflineCount] = useState(0);
    const {lastJsonMessage} = useWebSocket(`${REACT_APP_WS_URL}/getDeviceOnlineStatus`);

    useEffect(() => {
        if (lastJsonMessage !== null) {
            const data = lastJsonMessage;
            setOnlineCount(data.online_count);
            setOfflineCount(data.offline_count);
        }
    }, [lastJsonMessage]);

    return (
        <Box
            gridColumn={gridColumn}
            gridRow={gridRow}
            backgroundColor={colors.primary[400]}
            display="flex"
        >
            <StatBox
                title={onlineCount}
                subtitle="Online"
                progress={onlineCount / (onlineCount + offlineCount)}
                icon={
                    <LockOpenIcon
                        sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                }
            />
        </Box>
    );
};

export default OnlineDevice;