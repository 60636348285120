import { useState, createContext } from 'react'; 
import { ColorModeContext, useMode } from './theme';
import { CssBaseline, ThemeProvider } from "@mui/material";
import Topbar from './scenes/Topbar';
import Main from './scenes/Main';

export const ZoomMachinesContext = createContext();

function App() {
    const [theme, colorMode] = useMode();
    const [ZoomMachines, SetZoomMachines] = useState([]);

    return (
        <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
        <ZoomMachinesContext.Provider value={ZoomMachines}>
            <CssBaseline />
            <div className='app'>
                <main className='content'>
                    <Topbar />
                    <Main />
                </main>
            </div>
        </ ZoomMachinesContext.Provider>
        </ ThemeProvider>
        </ ColorModeContext.Provider>
    )
}

export default App;