import { useState, useEffect } from "react";
import useWebSocket from "react-use-websocket";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import CanvasJSReact from '@canvasjs/react-charts';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const {REACT_APP_WS_URL} = process.env;

const ErrorRateChart = ({gridColumn="span 6", gridRow="span 4"}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const completeErrorRateOption = (option) => {
        option['backgroundColor'] = colors.primary[400];
        option['axisX']['labelFontColor'] = colors.grey[100];
        option['axisY']['labelFontColor'] = colors.grey[100];
        option['axisX']['titleFontColor'] = colors.grey[100];
        option['axisY']['titleFontColor'] = colors.grey[100];
        option['title']['fontColor'] = colors.grey[100];
        option['legend'] = {'fontColor': colors.grey[100]};
    }
    const transformErrorRate = (errorRate) => {
        return errorRate.map((error, index) => {
            return {
                x: index,
                y: error
            }
        })
    }

    const [errorRateOption, setErrorRateOption] = useState({
        animationEnabled: true,	
        title:{
            text: "Training and Validation Error Rate",
            fontSize: 18
        },
        axisX : {
            title: "Epoch",
            minimum: 0,
        },
        axisY : {
            title: "Error Rate",
            maximum: 1,
            minimum: 0,
        },
        toolTip: {
            shared: true
        },
        data: [{
            type: "spline",
            name: "Training",
            showInLegend: true,
            dataPoints: []
        },
        {
            type: "spline",
            name: "Validation",
            showInLegend: true,
            dataPoints: []
        }]
    });

    const {lastJsonMessage} = useWebSocket(`${REACT_APP_WS_URL}/getTrainingStatus`,{
        share: false,
        shouldReconnect: () => true,
    });
    completeErrorRateOption(errorRateOption); 

    useEffect(() => {
        if (lastJsonMessage !== null) {
            const data = lastJsonMessage;
            const training_error_rate_list = transformErrorRate(data.training_error_rate_list);
            const validation_error_rate_list = transformErrorRate(data.validation_error_rate_list);
            errorRateOption.axisX.maximum = data.total_epoch;
            setErrorRateOption((errorRateOption) =>{
                return {
                    ...errorRateOption,
                    data: [
                        {
                            ...errorRateOption.data[0],
                            dataPoints: training_error_rate_list
                        },
                        {
                            ...errorRateOption.data[1],
                            dataPoints: validation_error_rate_list
                        }
                    ]
                }
        })
        }
    }, [lastJsonMessage]);


    return (
        <Box
            gridColumn={gridColumn}
            gridRow={gridRow}
            backgroundColor={colors.primary[400]}
        >
            <CanvasJSChart containerProps={{height:"100%"}} options={errorRateOption} />
        </Box>
    )
}

export default ErrorRateChart;