import { useInternalNode, getStraightPath, EdgeLabelRenderer } from '@xyflow/react';
import { getEdgeParams } from '../utils.js';
import { useTheme } from "@mui/material";
import { tokens } from "../theme.js";

// Customize Edge Text to show connection ports
function EdgeLabel({ transform, label }) {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <div
      style={{
        position: 'absolute',
        background: 'transparent',
        color: colors.grey[200],
        fontSize: 10,
        fontWeight: 700,
        transform,
      }}
      className="nodrag nopan"
    >
      {label}
    </div>
    
  );
}

// Customize edge to determine handle position automatically
function FloatingEdge({ id, source, target, style, data}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const sourceNode = useInternalNode(source);
  const targetNode = useInternalNode(target);

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);

  const [edgePath, labelX, labelY] = getStraightPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        style = {{...style, stroke: colors.grey[200]}}
      />
      <EdgeLabelRenderer>
        {data.outLabel && (
          <EdgeLabel
            transform={`translate(-50%, 0%) translate(${sx + 10}px,${sy - 3}px)`}
            label={data.outLabel}
          />
        )}

        {data.inLabel && (
          <EdgeLabel
            transform={`translate(-50%, -100%) translate(${tx - 10}px,${ty + 3}px)`}
            label={data.inLabel}
          />
        )}
      </EdgeLabelRenderer>
    </>

  );
}

export default FloatingEdge;
